<template>
  <div class="newsroom">
    <div class="columns is-variable-grid is-1 is-mobile is-aligned-middle">
      <div v-if="!$isEmpty(newsroom)" class="column is-narrow">
        <div class="newsroom__logo">
          <router-link
            :to="newsroomPathConfig"
            class="newsroom__logo-anchor"
          >
            <round-avatar
              :main-color="newsroom.main_color"
              :src="newsroomLogo"
              :acronym-fallback="newsroom.name"
              size="xsmall"
            />
          </router-link>
        </div>
      </div>

      <div v-if="!$isEmpty(newsroom)" class="column has-min-width">
        <router-link
          :title="newsroom.name"
          :to="newsroomPathConfig"
          class="newsroom__title"
          data-testid="StoryCard_NewsroomLink"
        >
          {{ newsroom.name }}
        </router-link>
      </div>

      <div class="column is-narrow m-l-a">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newsroom: { type: Object, required: true },
    story: { type: Object, required: true },
    newsroomPathName: { type: String, required: true }
  },

  computed: {
    newsroomLogo () {
      return this.$safeGet(this.newsroom, 'logo.cropped')
    },

    newsroomPathConfig () {
      return {
        name: this.newsroomPathName,
        params: { newsroomId: this.newsroom.id, newsroomSlug: this.story.newsroom_slug }
      }
    }
  }
}
</script>
