<template>
  <social-sharing
    :url="url"
    :title="title"
    :description="description"
    inline-template
  >
    <div class="social-share-buttons">
      <network network="facebook">
        <button class="button is-round is-black ">
          <span class="icon">
            <i class="hf hf-facebook" />
          </span>
        </button>
      </network>
      <network network="twitter">
        <button class="button is-round is-black">
          <span class="icon">
            <i class="hf hf-twitter" />
          </span>
        </button>
      </network>
      <network network="linkedin">
        <button class="button is-round is-black ">
          <span class="icon">
            <i class="hf hf-linkedin" />
          </span>
        </button>
      </network>
      <a
        :href="'mailto:?subject=' + encodeURIComponent(title) + '&body=' + url"
        target="_blank"
        title="Email"
        class="button is-round is-black"
      >
        <span class="icon">
          <v-icon type="envelope" />
        </span>
      </a>
      <el-tooltip
        :content="$t('general.copy_link_to_clipboard')"
        class="item"
        placement="top-start"
      >
        <v-button
          v-clipboard="url"
          class="is-round is-black Story__CopyLink"
          @success="$notify.success($t('success.url_copied'))"
        >
          <span class="icon">
            <v-icon type="share-alt" />
          </span>
        </v-button>
      </el-tooltip>
    </div>
  </social-sharing>
</template>

<script>
export default {
  name: 'ShareOn',
  props: {
    url: {
      type: String,
      default () {
        return this.$getUrl(this.$route.fullPath, 'hypenews', null)
      }
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.social-share-buttons {
  & > * {
    &:last-child {
      margin-right: 0;
    }

    margin-right: 5px;
    margin-bottom: 5px;
  }
}
</style>
