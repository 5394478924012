<template>
  <div>
    <loadMore
      :endpoint="suggestedStoriesEndpoint"
      :fetching-text="$t('pages.dashboard.fetching_suggested_stories')"
      width="column is-4 is-flex-tablet is-column"
    >
      <template slot="item" slot-scope="props">
        <storyCard
          :story="props.item"
        />
      </template>
    </loadMore>
  </div>
</template>
<script>
import StoryCard from '@/components/storySearch/StoryCard'

export default {
  components: {
    StoryCard
  },
  data () {
    return {
      suggestedStoriesEndpoint: 'user/suggested/stories?include=newsroom&sort[]=newest'
    }
  }
}
</script>
