<template>
  <div
    :class="extraClasses"
    class="story"
    data-testid="StoryCard"
  >
    <div class="story__head">
      <router-link
        v-if="!(isEmbedded && storySettings.storyImage)"
        :to="linkPath"
        class="story__thumb-anchor"
      >
        <div class="story__thumb responsive-embed widescreen">
          <div
            v-observe-visibility="observeCallback"
            :style="coverStyle"
            class="story__thumb-inner"
          >
            <!-- NEWSROOM DATA -->
            <span v-if="!newsroomLogo && !hasCover">{{ newsroom.name }}</span>
          </div>
        </div>
      </router-link>
      <div
        class="StoryCard__newsroomMeta"
        :class="{ 'is-position-absolute': !(isEmbedded && storySettings.storyImage) }"
      >
        <story-card-newsroom-head
          :newsroom="newsroom"
          :story="story"
          :newsroom-path-name="newsroomPathName"
        >
          <el-popover
            placement="right"
            popper-class="storyShare__Dropdown"
            trigger="hover"
          >
            <share-on
              :url="fullUrl"
              :title="story.title"
              :description="story.boilerplate"
            />

            <v-button slot="reference" class="is-black is-small is-round" data-testid="StoryCard__share">
              <span class="icon">
                <v-icon type="forward" />
              </span>
            </v-button>
          </el-popover>
          <a
            v-if="isEditable"
            :href="editUrl"
            target="hn_story_edit"
            class="StoryCard__edit-icon button is-small is-round is-primary m-l-s"
          >
            <span class="icon">
              <v-icon type="pencil" size="lg" />
            </span>
          </a>
        </story-card-newsroom-head>
      </div>
    </div>

    <div class="story__body">
      <div class="story__content">
        <router-link
          :to="linkPath"
          data-testid="StoryCard_StoryLink"
        >
          <!-- STORY META -->
          <div class="StoryCard__meta">
            <div
              v-if="!(isEmbedded && storySettings.dateStamp)"
              class="has-nowrap is-size-7 m-r-m"
            >
              <time
                :datetime="story.published_at"
                class="timeAndDate"
              >
                {{ datetime(story.published_at, 'MMMM Do') }}
              </time>
            </div>

            <div
              v-if="story.type && !(isEmbedded && storySettings.storyTypeTag)"
              :title="$t('components.story_types.' + story.type) "
              class="story__type has-ellipsis is-size-7"
            >
              {{ $t('components.story_types.' + story.type) }}
            </div>
          </div>

          <h3
            :title="story.headline"
            class="story__title"
          >
            {{ truncate(story.headline, 60) }}
          </h3>

          <div v-if="!hideContent" class="story__text" :class="{ 'is-hidden-touch': !hideContent }">
            {{ stripHtml(truncate(secondaryContentToShow, copyTextChars)) }}
          </div>
        </router-link>
      </div>

      <div v-if="!fromNewsroomPage" class="story__footer">
        <div class="columns is-gapless is-mobile is-aligned-middle">
          <div v-if="newsroomCountry" class="column is-flex is-aligned-middle newsroom__country-meta">
            <v-icon type="globe" size="lg" />
            <span class="has-text-weight-semibold m-l-s">{{ newsroomCountry.name }}</span>
          </div>

          <div class="column is-narrow m-l-a">
            <follow-newsroom-button :newsroom="newsroom" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'
import StripHtmlMixin from '@hypefactors/shared/js/mixins/StripHtmlMixin'
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'

import StoryCardNewsroomHead from './StoryCardNewsroomHead'
import ShareOn from '../ShareOn'

export default {
  components: {
    ShareOn,
    StoryCardNewsroomHead
  },

  mixins: [DatetimeMixin, StripHtmlMixin, TruncateMixin],

  props: {
    story: {
      type: Object,
      required: true
    },
    isLarger: {
      type: Boolean,
      default: false
    },
    newsroomProp: {
      type: Object,
      default: () => ({})
    },
    showLessInfo: {
      type: Boolean,
      default: false
    },
    showPinnedPosts: {
      type: Boolean,
      default: false
    },
    storyPathName: {
      type: String,
      default: 'story'
    },
    newsroomPathName: {
      type: String,
      default: 'newsroom.stories'
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    hideContent: {
      type: Boolean,
      default: false
    },
    copyTextChars: {
      type: Number,
      default: 200
    }
  },

  data () {
    return {
      inViewport: false,
      observeCallback: {
        callback: this.onViewportEnter,
        once: true
      }
    }
  },

  computed: {
    ...mapGetters([
      'isEmbedded',
      'storySettings'
    ]),

    coverStyle () {
      let style = {}
      if (this.inViewport) {
        style = {
          backgroundImage: `url('${this.storyCover}')`
        }
      }
      if (!this.hasCover) {
        style = {
          ...style,
          borderColor: this.newsroom.main_color,
          backgroundColor: this.newsroom.main_color
        }
      }
      return style
    },

    extraClasses () {
      let classes = []

      if (!this.hasCover) {
        classes.push('story--no-cover')
      }

      if (this.shouldDisplayAsPinned) {
        classes.push('columns is-pinned is-gapless')
      }

      if (this.inViewport) {
        classes.push('story--in-viewport')
      }

      return classes
    },

    shouldDisplayAsPinned () {
      return this.showPinnedPosts && this.isPinned
    },

    isPinned () {
      return this.story.is_pinned
    },

    newsroom () {
      const newsroom = this.$safeGet(this.story.newsroom, 'data', {})
      return this.$isEmpty(newsroom) ? this.newsroomProp : newsroom
    },

    hasCover () {
      return !!this.story.cover.cropped
    },

    storyCover () {
      if (!this.hasCover) {
        return this.newsroomLogo
      }

      return ((this.isLarger || this.shouldDisplayAsPinned) && this.hasScaledSizes)
        ? this.$safeGet(this.story, 'cover.scaled.medium.path', this.story.cover.cropped)
        : this.story.cover.cropped
    },

    hasScaledSizes () {
      return !!this.story.cover.scaled
    },

    newsroomLogo () {
      return this.$safeGet(this.newsroom, 'logo.cropped')
    },

    newsroomCountry () {
      return this.$safeGet(this.newsroom, 'country.data', null)
    },

    fromNewsroomPage () {
      return this.showLessInfo || !this.story.newsroom
    },

    fullUrl () {
      const route = this.$router.resolve({
        name: 'story',
        params: this.pathParams
      })

      return this.$getUrl(route.href, 'hypenews', null)
    },

    pathParams () {
      return {
        storyId: this.story.id,
        storySlug: this.story.slug,
        newsroomId: this.newsroom.id,
        newsroomSlug: this.story.newsroom_slug
      }
    },

    linkPath () {
      return {
        name: this.storyPathName,
        params: this.pathParams
      }
    },

    secondaryContentToShow () {
      return this.story.subheadline || this.story.excerpt
    },

    editUrl () {
      return this.$getUrl(`stories/story/${this.story.id}/edit?brand=${this.newsroom.brand.id}`, 'hypefactors', false)
    }
  },

  methods: {
    onViewportEnter (isVisible, instance) {
      this.inViewport = isVisible
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.story {
  display: flex;
  position: relative;
  flex-flow: column;
  flex: 1 1 auto;
  max-width: 100%;
  background: $white;
  width: 100%;

  &.is-black {
    background: $hf__color-black;

    &, a, .story__title {
      color: $hf__color-white;
    }

    .FollowNewsroomButton {
      background: $hf__color-white;
      color: $hf__color-black;
    }
  }

  &--no-cover {
    .story__thumb-inner {
      border: solid $grey-lighter;
      border-width: $spacing $spacing-small;
      background-size: contain;
    }
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
  }

  &__thumb {
    overflow: hidden;
    position: relative;
    text-align: center;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 0;

    &-inner {
      filter: blur(5px);
      display: flex;
      justify-content: center;
      align-items: center;
      background: center/cover no-repeat $grey-lighter;
      transition: .3s ease-in;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .story--in-viewport & {
        filter: none;
      }
    }
  }

  &__title {
    font-size: $size-3;
    font-weight: 600;
    line-height: 1;
  }

  &__head {
    position: relative;
  }

  &__type {
    font-size: $size-7;
    color: $hf__text-muted;
  }

  &__content {
    padding: $spacing-small;
    margin-bottom: 0;
    /*min-height: 140px;*/
    flex: 1 1 auto;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__footer {
    border-top: 1px solid $white-ter;
    padding: $spacing-small;
  }

  .newsroom {
    padding: $padding-small;

    &__title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      max-width: 100%;
      padding: 0 $padding-small;
      background: $hf__color-black;
      color: $hf__color-white;
      vertical-align: middle;
      margin-right: $margin-small;
    }

    &__logo {
      padding-right: $spacing-small;
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-anchor {
        border-radius: 50%;
        overflow: hidden;
        display: block;
      }
    }

    &__country {
      font-weight: 600;
    }

    .divider::after {
      content: "·";
    }
  }

  &.is-pinned {
    flex-flow: column;

    .story {
      &__title {
        font-size: 1.4rem;
      }

      &__thumb {
        border-left: $hf__border--base;
        height: 100%;
        width: 100%;

        &-anchor {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .StoryCard__meta {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-tiny;
  }

  .StoryCard__newsroomMeta {
    width: 100%;
    top: 0;
  }
}

@include desktop {
  .story {
    .is-large-story & {
      &.story--no-cover .story__thumb-inner {
        background-size: auto;
      }
    }

    &.is-pinned.story--no-cover {
      .story__thumb-inner {
        background-size: auto;
      }
    }

    &.is-pinned {
      flex-flow: row;

      .story {
        &__head {
          flex: 0 0 66.66%;
          order: 2;
          display: flex;
        }

        &__body {
          flex: 0 0 33.3%;
          order: 1;
        }
      }
    }
  }
}
</style>
